<template>
  <div v-if="Object.keys(generator_info).length !== 0">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <b>Generator Brand: </b> {{ generator_info.generator_brand }}<br>
            <b>Engine Brand: </b> {{ generator_info.engine_brand }}<br>
          </div>
          <div class="col-sm-6">
            <b>Asset Tag No.: </b> {{ generator_info.asset_tag_no }}<br>
            <b>Model No.: </b> {{ generator_info.model_no }}<br>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <a-tooltip placement="top">
            <template slot="title"><span>Add Fuel Consumption</span></template>
            <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-generator-fuel-consumption-modal @click="$bvModal.show('add-generator-fuel-consumption-modal')">
              <i class="fa fa-plus"/> Add Fuel Consumption
            </a>
          </a-tooltip>
          <table class="table table-sm table-bordered text-center">
            <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Run Time(Hr)</th>
              <th>Fuel Refill Status (Ltr)</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(generator_fuel_consumption, index) in generator_fuel_consumptions.data" :key="index">
              <td>{{ generator_fuel_consumptions.from + index }}</td>
              <td>{{ generator_fuel_consumption.consumption_date }}</td>
              <td>{{ generator_fuel_consumption.running_hours }}</td>
              <td>{{ generator_fuel_consumption.consumption_liter }}</td>
              <td>
                <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(generator_fuel_consumption.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="generator_fuel_consumptions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="generator_fuel_consumptions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <add-generator-fuel-consumption @search="search" :generator_info="generator_info"></add-generator-fuel-consumption>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import { mapGetters } from 'vuex'
import addGeneratorFuelConsumption from '@/views/generator/generatorFuelConsumption/create'

export default {
  name: 'create',
  components: { Empty, addGeneratorFuelConsumption },
  data() {
    return {
      generator_info: {},
      generator_fuel_consumptions: {},
      btnLoading: false,
      loading: false,
      loader: false,
      flag: false,
      show: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        date_range: '',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted: function () {
    this.getGeneratorInfo()
  },
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    getGeneratorInfo() {
      const generatorInfoId = this.$route.params.generator_info_id
      apiClient.get('api/generator-info/' + generatorInfoId)
        .then(response => {
          this.generator_info = response.data.generator_info
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          this.search_data.generator_info_id = this.$route.params.generator_info_id
          apiClient.post('api/generator-fuel-consumption/search', this.search_data)
            .then(response => {
              this.flag = true
              this.loading = false
              this.btnLoading = false
              this.generator_fuel_consumptions = response.data.generator_fuel_consumptions
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    getResults(page = 1) {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.search_data.generator_info_id = this.$route.params.generator_info_id
          apiClient.post('api/generator-fuel-consumption/search?page=' + page, this.search_data)
            .then(response => {
              this.generator_infos = response.data.generator_infos
            })
        }
      })
    },
    saveGeneratorMaintenanceInfo() {
      this.$bvModal.show('add-generator-maintenance-info-modal')
    },
    onDelete(generatorFuelConsumptionId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/generator-fuel-consumption/' + generatorFuelConsumptionId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.search()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>

</style>
