<template>
  <div>
    <div class="mb-1">
      <b-modal id="add-generator-fuel-consumption-modal" title="Generator Fuel Consumption" size="lg" hide-footer  @hidden="onHidden">
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <form action="" enctype="multipart/form-data" files="true" role="form">
          <div class="modal-content bg-white rounded">
            <div class="form-row">
              <div class="col-md-6">
                <label for="consumption_date" class="col-form-label col-form-label-sm">Consumption Date <span class="custom-required">*</span></label>
                <datepicker :format="customConsumptionDateFormat" ref="customConsumptionDateFormat" v-model="consumption_date" :class="errors.has('consumption_date') ? 'error-border-color' : ''"  v-validate="{ rules: { required:  true } }" id="consumption_date"  name="consumption_date" placeholder="Select consumption date" ></datepicker>
                <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('consumption_date')">Consumption date is required</div>
              </div>
              <div class="col-md-6">
                <label for="consumption_liter" class="col-form-label col-form-label-sm">Consumption Liter <span class="custom-required">*</span></label>
                <input type="text" v-model="consumption_liter" v-validate="{ required: true, max: 5, regex: /^[0-9]*(?:\.[0-9]{0,2})?$/ }"  name="consumption_liter" id="consumption_liter" class="form-control form-control-sm" placeholder="Enter consumption liter">
                <div class="invalid-feedback">Consumption liter is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6">
                <label for="running_hours" class="col-form-label col-form-label-sm">Running Hours <span class="custom-required">*</span></label>
                <input type="text" v-model="running_hours" v-validate="{ rules: { required:  true }, max: 4 }" name="running_hours" id="running_hours" class="form-control form-control-sm" placeholder="Enter running hours">
                <div class="invalid-feedback">Running hours is required</div>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <a-button type="primary" class="text-center mr-2" :loading="submitLoading"  @click.prevent="save">Add</a-button>
                <a-button type="danger" class="text-center" @click="$bvModal.hide('add-generator-fuel-consumption-modal')">Cancel</a-button>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'create',
  components: { Datepicker },
  props: ['generator_info'],
  data() {
    return {
      submitLoading: false,
      consumption_date: '',
      consumption_liter: '',
      running_hours: '',
      validation_errors: {},
      show: false,
    }
  },
  mounted() {
    //
  },
  methods: {
    customConsumptionDateFormat(date) {
      const consumptionDate = moment(date).format('YYYY-MM-DD')
      this.consumption_date = consumptionDate
      return consumptionDate
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.submitLoading = true
          const formData = new FormData()
          formData.append('consumption_date', this.consumption_date)
          formData.append('consumption_liter', this.consumption_liter)
          formData.append('running_hours', this.running_hours)
          formData.append('generator_info_id', this.generator_info.id)
          formData.append('hub_id', this.generator_info.hub_id)
          apiClient.post('/api/generator-fuel-consumption/store', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.submitLoading = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.consumption_date = ''
                this.consumption_liter = ''
                this.running_hours = ''
                this.$emit('search')
                this.$bvModal.hide('add-generator-fuel-consumption-modal')
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.submitLoading = false
              this.validation_errors = error.response.data.errors
              this.show = true
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    onHidden () {
      // this.generator_info_id = ''
    },
    resetForm() {
      this.show = false
      this.consumption_date = ''
      this.consumption_liter = ''
      this.running_hours = ''
    },
  },
}
</script>

<style scoped>
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
.error{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
